import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { LogInContainer, LogInTitle, SpaceBackground, VerifyText } from "../../../assets/styles/logIn.style";
import { StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { ContainerKYCBtn, FormContainer, Instructions, UploadSection } from "../../../assets/styles/kycStyle";
import { BackArrow } from "../../../components/authComponents/BackButton";
import DocViews from '../../../assets/img/doc-views.png';
import { useNavigate } from "react-router-dom";
import { useKYCStore } from "../../../store/user/setUserKYC";
import { NavigateBtn } from "../../../components/authComponents/NextBtn";
import { FormattedMessage } from "react-intl";
import { kycInfo } from "../../../const/kycRequirements/kycInfo";
export var KYCInfo = function () {
    var navigate = useNavigate();
    var userKYCObj = useKYCStore().userKYCObj;
    useEffect(function () {
        if (!userKYCObj.document && !userKYCObj.documentCountry) {
            navigate("/kyc-select");
        }
    }, [userKYCObj, navigate]);
    return (_jsx(SpaceBackground, { children: _jsx(LogInContainer, { children: _jsx(StyledRow, { "$justifyContent": "center", style: { height: "100%" }, children: _jsx(StyledCol, { "$justifyContent": "center", "$lg": 7, "$md": 12, children: _jsxs(FormContainer, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "kyc_info_title" }) }), _jsxs(VerifyText, { children: [_jsx(FormattedMessage, { id: "kyc_info_description1" }), " ", _jsx("br", {}), " ", _jsx(FormattedMessage, { id: "kyc_info_description2" })] }), _jsx(UploadSection, { children: _jsx("img", { src: DocViews, className: "docViews", alt: "docViews" }) }), _jsx(Instructions, { children: kycInfo.map(function (_a) {
                                    var messageId = _a.messageId, defaultMessage = _a.defaultMessage;
                                    return (_jsx("li", { children: _jsx(FormattedMessage, { id: messageId, defaultMessage: defaultMessage }) }, messageId));
                                }) }), _jsxs(ContainerKYCBtn, { children: [_jsx(BackArrow, {}), _jsx(NavigateBtn, { link: "/kyc-doc", validate: true, width: "50%" })] })] }) }) }) }) }));
};
