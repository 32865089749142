export var fr_locale = {
    next_btn: 'Suivant',
    have_an_account: 'Vous avez déjà un compte ?',
    log_in: 'Se connecter',
    sign_up: "S'inscrire",
    auth_title1: 'Bienvenue',
    auth_title2: 'chez Quanta',
    line_text: 'ou',
    sign_up_with_google: "S'inscrire avec Google",
    sign_up_with_apple: "S'inscrire avec Apple",
    log_in_title: 'Bon retour',
    email_phone_input_label: 'Email/Numéro de téléphone',
    password_input_label: 'Mot de passe',
    didt_remember_password: 'Vous n\'vous souvenez plus du mot de passe ?',
    change_password_title: 'Nouveau mot de passe',
    password_check_input_label: 'Vérifier le mot de passe',
    reset_password_title: 'Réinitialiser le mot de passe',
    email_input_label: 'Email',
    email_verification_title: 'Vérification de l\'email',
    email_verification_description: 'Veuillez entrer le code de vérification à 6 chiffres qui a été envoyé à',
    email_verification_description2: 'Si vous ne voyez pas l\'e-mail dans votre boîte de réception, vérifiez votre dossier spam ou courrier indésirable, car il pourrait y avoir été filtré.',
    email_code_title: 'Code de l\'email',
    wait_code: 'Attendre pour renvoyer le code',
    not_get_code: 'Vous n\'avez pas reçu le code ?',
    phone_verification_title: 'Vérification du téléphone',
    phone_verification_text1: 'Veuillez entrer le code de vérification à 6 chiffres',
    phone_verification_text2: 'qui a été envoyé à votre numéro de téléphone',
    input_phone_label: 'Téléphone',
    input_code_label: 'Code',
    creating_account_title: 'Création d\'un compte',
    terms_and_policy1: 'En créant un compte',
    terms_and_policy2: ', je suis d\'accord avec',
    terms_and_policy3: ' les ',
    terms_and_policy4: 'Conditions d\'utilisation',
    terms_and_policy5: ' et ',
    terms_and_policy6: 'Politique de confidentialité',
    email_verification_code1: 'Veuillez entrer le code de vérification à 6 chiffres',
    email_verification_code2: 'qui a été envoyé à',
    email_verification_code3: 'Le code est valable pendant 30 minutes.',
    email_verification_label: 'Code de vérification par e-mail',
    home_adress_title: 'Adresse domicile',
    home_adress_description: 'Assurez-vous qu\'il s\'agit de votre adresse actuelle.',
    full_adress_label: 'Adresse complète',
    postal_code_label: 'Code postal',
    city_label: 'Ville',
    region_label: 'Région',
    password_creation_title: 'Mot de passe',
    password_check_label: 'Vérifier le mot de passe',
    personal_information_title: 'Informations personnelles',
    personal_information_description1: 'Veuillez fournir les informations suivantes telles qu\'indiquées',
    personal_information_description2: 'sur votre passeport ou carte d\'identité.',
    full_name_label: 'Nom complet',
    data_about_user: 'Les données doivent correspondre à celles du passeport ou de la carte d\'identité.',
    date_birth_label: 'Date de naissance',
    phone_verification_code_label: 'Code de vérification par téléphone',
    residensy_title: 'Vérifions votre identité',
    residensy_title_description: 'Tout d\'abord, sélectionnez votre pays de résidence.',
    residency_text: 'Assurez-vous que votre pays de résidence est correct.',
    residency_option: "Résidence",
    citizenship_option: "Citoyenneté",
    back_btn_text: "Retour",
    document_select_title: 'Vérification du document',
    document_select_description: 'Sélectionnez le pays/région de délivrance de votre document.',
    document_select_warning: 'Utilisez un document valide émis par le gouvernement',
    document_select_text1: 'Seuls les documents suivants listés ci-dessous ',
    document_select_text2: 'seront acceptés ; tous les autres documents seront ',
    document_select_text3: 'rejetés.',
    id_card_text: 'Carte d’identité',
    passport_text: 'Passeport',
    kyc_info_title: 'Vérification du document',
    kyc_info_description1: 'Prenez une photo des deux côtés de votre document ',
    kyc_info_description2: 'émis par le gouvernement.',
    kyc_info_description3: 'pasaporte',
    reqiurements_kyc1: 'Le document n’est pas expiré',
    reqiurements_kyc2: 'Ceci est un document original, pas une copie ou un scan',
    reqiurements_kyc3: 'Retirez tout porte-document ou couverture pour éviter les reflets ou le flou',
    reqiurements_kyc4: 'Placez le document sur un fond uni',
    reqiurements_kyc5: 'Le document doit être tenu droit, non incliné ou tourné.',
    picture_card_text: 'Déposez votre photo ici ou sélectionnez',
    picture_card_signed_text: 'Cliquez pour parcourir',
    picture_box_front: 'Recto de la carte d’identité',
    picture_box_back: 'Verso de la carte d’identité',
    picture_passport: 'Photo du passeport',
    kyc_person_label: 'Vérification de l’identité',
    kyc_person_description: 'Prenez une photo de vous avec votre document en main.',
    photo_label: 'Votre photo',
    person_requirements1: 'L’image doit être nette, sans flou ni pixellisation.',
    person_requirements2: 'Le visage de l’utilisateur et le document doivent être entièrement visibles et non rognés.',
    person_requirements3: 'Les informations sur le document doivent être clairement visibles et non couvertes par les doigts.',
    person_requirements4: 'Le document doit être tenu droit, non incliné ou tourné.',
    verify_btn: 'Vérifier',
    verify_main_text: 'Vérifions maintenant votre identité pour utiliser toutes les fonctionnalités de Quanta.',
    acccount_created_title: 'compte créé',
    account_verified_title: 'compte vérifié',
    registartion_success_title: 'Inscription réussie',
    auth_success_title: 'Autorisation réussie',
    auth_login: 'Utilisez maintenant toutes les fonctionnalités de Quanta',
    kyc_text: 'Vous serez informé des résultats plus tard.',
    kyc_title: 'KYC envoyé pour vérification',
    not_now: 'Pas maintenant',
    password_requirements1: "Au moins 8 caractères",
    password_requirements2: "Au moins 1 chiffre",
    password_requirements3: "Au moins 1 lettre majuscule",
    password_requirements4: "Les mots de passe doivent correspondre",
    apple_btn: "S'inscrire avec Apple",
    adress_palceholder: "Adresse",
    first_name_placeholder: "Prénom",
    last_name_placeholder: "Nom de famille",
    invalid_file_type: 'Type de fichier invalide. Veuillez télécharger une image au format PNG, JPEG ou JPG.',
    incorrect_code: 'Le code saisi est incorrect. Veuillez réessayer.',
    upload_file: 'Veuillez télécharger un fichier.',
    missing_token: 'Le jeton d\'autorisation est manquant.',
    picture_missing: 'L\'image du document (avant ou arrière) est manquante.',
    document_type: 'Le type de document ou le pays est manquant.',
    existing_document: 'Un document existe déjà pour cet utilisateur.',
    failed_kyc: 'Échec de la soumission des détails KYC.',
    unexpected_error: 'Une erreur inattendue est survenue. Veuillez réessayer.',
    password_changed: 'Mot de passe changé. Retour à la page précédente.',
    failsed_reset_password: 'Échec de la réinitialisation du mot de passe.',
    error_occured: 'Erreur lors de la réinitialisation.',
    login_failed: 'Échec de la connexion, veuillez réessayer.',
    email_send_error: 'Erreur lors de l\'envoi du code de vérification par e-mail.',
    parsing_error: 'Une erreur inattendue est survenue lors de l\'analyse de la réponse.',
};
