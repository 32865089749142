var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AuthInput, AuthInputLabel, FormContainer, FormContainerText, IconWrapper, InputWrapper, LogInTitle, } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow, PositionLayout } from "../../../assets/styles/mainPageStyles";
import React, { useEffect, useState } from "react";
import { BackArrow } from "../../../components/authComponents/BackButton";
import UFOIcon from "../../../components/UFOIcon/UFOIcon";
import { hasCapitalLetter, hasNumber, isEqual, isLongEnough } from "../../../const/validations/validation";
import { useSearchParams } from "react-router-dom";
import { Alert } from "../../../components/authComponents/Alert";
import { FormattedMessage, useIntl } from "react-intl";
export var ChangePassword = function () {
    var _a = useState(true), isHidePass = _a[0], setIsHidePass = _a[1];
    var _b = useState(true), isHideCheckPass = _b[0], setIsHideCheckPass = _b[1];
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(""), confirmPassword = _d[0], setConfirmPassword = _d[1];
    var searchParams = useSearchParams()[0];
    var _e = useState(''), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(false), isSuccess = _f[0], setIsSuccess = _f[1];
    var _g = useState(false), showAlert = _g[0], setShowAlert = _g[1];
    var intl = useIntl();
    var token = searchParams.get("token") || "";
    useEffect(function () {
        if (token === "") {
            setIsSuccess(true);
            setErrorMessage("password_changed");
            setShowAlert(true);
        }
    }, [token]);
    var sendPasswordChange = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var response, responseData, error_1;
        var password = _b.password, token = _b.token;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/reset-password"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                newPassword: password,
                                reset_token: token,
                            }),
                        })];
                case 1:
                    response = _c.sent();
                    if (response.status === 204) {
                        return [2 /*return*/, response];
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseData = _c.sent();
                    if (!response.ok) {
                        throw new Error(responseData.message || "failsed_reset_password");
                    }
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _c.sent();
                    console.error("Error sending password reset request:", error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        var isValid = isLongEnough(password) && hasNumber(password) && hasCapitalLetter(password) && isEqual(password, confirmPassword);
        return isValid;
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setShowAlert(false);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, sendPasswordChange({ password: password, token: token })];
                case 2:
                    response = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    setIsSuccess(false);
                    setErrorMessage("error_occured");
                    setShowAlert(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$alignItems": "start", "$lg": 2, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(BackArrow, {}) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsx(FormContainerText, { children: _jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: 'change_password_title' }) }) }), _jsxs(PositionLayout, { as: "form", display: "flex", flexDirection: "column", width: "85%", onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: 'password_input_label' }) }), _jsxs(InputWrapper, { children: [_jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'password_input_label' }), type: isHidePass ? 'password' : 'text', value: password, onChange: function (e) { return setPassword(e.target.value); } }), _jsx(IconWrapper, { onClick: function () { return setIsHidePass(!isHidePass); }, children: _jsx(UFOIcon, { isHidePass: isHidePass }) })] }), _jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: 'password_check_input_label' }) }), _jsxs(InputWrapper, { children: [_jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'password_check_input_label' }), type: isHideCheckPass ? 'password' : 'text', value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); } }), _jsx(IconWrapper, { onClick: function () { return setIsHideCheckPass(!isHideCheckPass); }, children: _jsx(UFOIcon, { isHidePass: isHideCheckPass }) })] }), _jsx(MainBtnStyle, { type: "submit", width: "50%", style: {
                                            marginTop: '8px',
                                            opacity: validateForm() ? "100%" : "50%",
                                            cursor: validateForm() ? "pointer" : "not-allowed",
                                        }, children: _jsx(FormattedMessage, { id: "next_btn" }) })] })] }) }), showAlert && (_jsx(Alert, { message: errorMessage, onClose: function () { return setShowAlert(false); }, isSuccess: isSuccess }))] }) }));
};
