var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { breakpoints } from "../../types/componentsProps/breakpoints";
var mediaQuery = function (breakpoint) {
    return "@media (min-width: ".concat(breakpoint, "px)");
};
var getColWidth = function (size) {
    if (!size)
        return;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 0 0 ", "%;\n    max-width: ", "%;\n  "], ["\n    flex: 0 0 ", "%;\n    max-width: ", "%;\n  "])), (size / 12) * 100, (size / 12) * 100);
};
var getJustifyContent = function (justifyContent) {
    if (!justifyContent)
        return;
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    justify-content: ", ";\n  "], ["\n    justify-content: ", ";\n  "])), justifyContent);
};
var getAlignItems = function (align) {
    if (!align)
        return;
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    align-items: ", ";\n  "], ["\n    align-items: ", ";\n  "])), align);
};
var getRowFlexDirection = function (flexDirection) {
    if (!flexDirection)
        return;
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex-direction: ", ";\n  "], ["\n    flex-direction: ", ";\n  "])), flexDirection);
};
export var GlobalContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  z-index: 1;\n  background-image: url(\"", "/images/background-site.webp\");\n  background-color: #000004;\n"], ["\n  position: relative;\n  z-index: 1;\n  background-image: url(\"", "/images/background-site.webp\");\n  background-color: #000004;\n"])), process.env.REACT_APP_API_URL);
export var OverflowContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100vw;\n  overflow-x: hidden;\n  overflow-y: scroll;\n\n   @media (max-width: 1024px) {\n     overflow-x: hidden;\n }\n\n"], ["\n  width: 100vw;\n  overflow-x: hidden;\n  overflow-y: scroll;\n\n   @media (max-width: 1024px) {\n     overflow-x: hidden;\n }\n\n"])));
export var StyledRow = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  // margin-right: -5px;\n  // margin-left: -5px;\n\n\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  // margin-right: -5px;\n  // margin-left: -5px;\n\n\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var $justifyContent = _a.$justifyContent;
    return getJustifyContent($justifyContent);
}, function (_a) {
    var $flexDirection = _a.$flexDirection;
    return getRowFlexDirection($flexDirection);
}, function (_a) {
    var $alignItems = _a.$alignItems;
    return getAlignItems($alignItems);
});
var getColJustify = function (justifyContent) {
    if (!justifyContent)
        return;
    return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    justify-content: ", ";\n  "], ["\n    justify-content: ", ";\n  "])), justifyContent);
};
var getColAlign = function (alignItems) {
    if (!alignItems)
        return;
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    align-items: ", ";\n  "], ["\n    align-items: ", ";\n  "])), alignItems);
};
var getColFlexDirection = function (flexDirection) {
    if (!flexDirection)
        return;
    return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    flex-direction: ", ";\n  "], ["\n    flex-direction: ", ";\n  "])), flexDirection);
};
export var StyledCol = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  padding: 20px;\n  box-sizing: border-box;\n  display: flex;\n\n\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"], ["\n  position: relative;\n  width: 100%;\n  padding: 20px;\n  box-sizing: border-box;\n  display: flex;\n\n\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var $justifyContent = _a.$justifyContent;
    return getColJustify($justifyContent);
}, function (_a) {
    var $alignItems = _a.$alignItems;
    return getColAlign($alignItems);
}, function (_a) {
    var $flexDirection = _a.$flexDirection;
    return getColFlexDirection($flexDirection);
}, function (_a) {
    var $xs = _a.$xs;
    return $xs && getColWidth($xs);
}, function (_a) {
    var $sm = _a.$sm;
    return $sm && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      ", " {\n        ", "\n      }\n    "], ["\n      ", " {\n        ", "\n      }\n    "])), mediaQuery(breakpoints.sm), getColWidth($sm));
}, function (_a) {
    var $md = _a.$md;
    return $md && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n      ", " {\n        ", "\n      }\n    "], ["\n      ", " {\n        ", "\n      }\n    "])), mediaQuery(breakpoints.md), getColWidth($md));
}, function (_a) {
    var $lg = _a.$lg;
    return $lg && css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n      ", " {\n        ", "\n      }\n    "], ["\n      ", " {\n        ", "\n      }\n    "])), mediaQuery(breakpoints.lg), getColWidth($lg));
}, function (_a) {
    var $xl = _a.$xl;
    return $xl && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n      ", " {\n        ", "\n      }\n    "], ["\n      ", " {\n        ", "\n      }\n    "])), mediaQuery(breakpoints.xl), getColWidth($xl));
});
export var Title = styled.h4(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: #ffffff;\n  font-weight: 700;\n  font-size: 44px;\n  text-align: center;\n  font-family: \"Syncopate\", sans-serif;\n  z-index: 3;\n\n  @media (max-width: 375px) {\n    font-size: 30px;\n  }\n"], ["\n  color: #ffffff;\n  font-weight: 700;\n  font-size: 44px;\n  text-align: center;\n  font-family: \"Syncopate\", sans-serif;\n  z-index: 3;\n\n  @media (max-width: 375px) {\n    font-size: 30px;\n  }\n"])));
export var ImgContainer = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */\n  filter: grayscale(100%);\n  z-index: 2;\n"], ["\n  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */\n  filter: grayscale(100%);\n  z-index: 2;\n"])));
export var BackgroundContainer = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 80vh;\n  overflow: hidden;\n\n"], ["\n  position: relative;\n  width: 100%;\n  height: 80vh;\n  overflow: hidden;\n\n"])));
export var MainBtnStyle = styled.button(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  background-color: #ffb801;\n  border-radius: 16px;\n  padding: 14px 14px 13px 20px;\n  min-width: ", ";\n  height: ", ";\n  border: none;\n  font-family: \"Kodchasan\", sans-serif;\n  font-weight: 700;\n  font-size: 14px;\n  color: #13131e;\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  text-align: center;\n\n    &:hover {\n      background-color: #FFE247;\n      box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n      box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n      color: #000004;\n      background-color: #E29500;\n    }\n\n    @media (max-width: 350px) {\n      min-width: 200px;\n    }\n"], ["\n  background-color: #ffb801;\n  border-radius: 16px;\n  padding: 14px 14px 13px 20px;\n  min-width: ", ";\n  height: ", ";\n  border: none;\n  font-family: \"Kodchasan\", sans-serif;\n  font-weight: 700;\n  font-size: 14px;\n  color: #13131e;\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  text-align: center;\n\n    &:hover {\n      background-color: #FFE247;\n      box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n      box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n      color: #000004;\n      background-color: #E29500;\n    }\n\n    @media (max-width: 350px) {\n      min-width: 200px;\n    }\n"])), function (_a) {
    var width = _a.width;
    return width || "265px";
}, function (_a) {
    var height = _a.height;
    return height || "46px";
});
export var EcosystemRightBlock = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  flex: 6.5;\n  box-sizing: border-box;\n  overflow: hidden;\n  \n  @media (max-width: 900px) {\n    flex: none;\n    width: 100%;\n    height: auto;\n    padding-bottom: 10%;\n  }\n\n  @media (max-width: 620px) {\n    padding-bottom: 0%;\n  }\n\n  & > div:first-child {\n    padding-left: 2%;\n  }\n  & > div:nth-child(2) {\n    padding-left: 0%;\n  }\n  & > div:nth-child(3) {\n    padding-left: 5%;\n  }\n\n"], ["\n  flex: 6.5;\n  box-sizing: border-box;\n  overflow: hidden;\n  \n  @media (max-width: 900px) {\n    flex: none;\n    width: 100%;\n    height: auto;\n    padding-bottom: 10%;\n  }\n\n  @media (max-width: 620px) {\n    padding-bottom: 0%;\n  }\n\n  & > div:first-child {\n    padding-left: 2%;\n  }\n  & > div:nth-child(2) {\n    padding-left: 0%;\n  }\n  & > div:nth-child(3) {\n    padding-left: 5%;\n  }\n\n"])));
export var PositionLayout = styled.div(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  padding: ", ";\n  margin: ", ";\n\n  display: ", ";\n  flex-direction: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  text-align: ", ";\n  gap: ", ";\n\n  justify-content: ", ";\n  align-items: ", ";\n  z-index: ", ";\n\n  position: ", ";\n  top: ", ";\n  right: ", ";\n  bottom: ", ";\n  left: ", ";\n\n  cursor: ", ";\n"], ["\n  padding: ", ";\n  margin: ", ";\n\n  display: ", ";\n  flex-direction: ", ";\n\n  width: ", ";\n  height: ", ";\n\n  text-align: ", ";\n  gap: ", ";\n\n  justify-content: ", ";\n  align-items: ", ";\n  z-index: ", ";\n\n  position: ", ";\n  top: ", ";\n  right: ", ";\n  bottom: ", ";\n  left: ", ";\n\n  cursor: ", ";\n"])), function (_a) {
    var padding = _a.padding;
    return padding || "0px";
}, function (_a) {
    var margin = _a.margin;
    return margin || "0px";
}, function (_a) {
    var display = _a.display;
    return display || '';
}, function (_a) {
    var flexDirection = _a.flexDirection;
    return flexDirection || "";
}, function (_a) {
    var width = _a.width;
    return width || "auto";
}, function (_a) {
    var height = _a.height;
    return height || "auto";
}, function (_a) {
    var textAlign = _a.textAlign;
    return textAlign || "";
}, function (_a) {
    var gap = _a.gap;
    return gap || "";
}, function (_a) {
    var justifyContent = _a.justifyContent;
    return justifyContent || "";
}, function (_a) {
    var alignItems = _a.alignItems;
    return alignItems || "";
}, function (_a) {
    var zIndex = _a.zIndex;
    return zIndex || "auto";
}, function (_a) {
    var position = _a.position;
    return position || "relative";
}, function (_a) {
    var top = _a.top;
    return top || "";
}, function (_a) {
    var right = _a.right;
    return right || "";
}, function (_a) {
    var bottom = _a.bottom;
    return bottom || "";
}, function (_a) {
    var left = _a.left;
    return left || "";
}, function (_a) {
    var cursor = _a.cursor;
    return cursor || 'auto';
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
