export var es_locale = {
    next_btn: 'Siguiente',
    have_an_account: '¿Ya tienes cuenta?',
    log_in: 'Iniciar sesión',
    sign_up: 'Registrarse',
    auth_title1: 'Bienvenido',
    auth_title2: 'a Quanta',
    line_text: 'o',
    sign_up_with_google: 'Registrarse con Google',
    sign_up_with_apple: 'Registrarse con Apple',
    log_in_title: 'Bienvenido de nuevo',
    email_phone_input_label: 'Correo electrónico/Número de teléfono',
    password_input_label: 'Contraseña',
    didt_remember_password: '¿No recuerdas tu contraseña?',
    change_password_title: 'Nueva contraseña',
    password_check_input_label: 'Verificar contraseña',
    reset_password_title: 'Restablecer contraseña',
    email_input_label: 'Correo electrónico',
    email_verification_title: 'Verificación de correo electrónico',
    email_verification_description: 'Por favor ingresa el código de verificación de 6 dígitos que se envió a',
    email_verification_description2: 'Si no ves el correo en tu bandeja de entrada, revisa tu carpeta de spam o correo no deseado, ya que podría haber sido filtrado allí.',
    email_code_title: 'Código de correo electrónico',
    wait_code: 'Esperar para reenviar el código',
    not_get_code: '¿No recibiste el código?',
    phone_verification_title: 'Verificación de teléfono',
    phone_verification_text1: 'Por favor ingresa el código de verificación de 6 dígitos',
    phone_verification_text2: 'que se envió a tu número de teléfono',
    input_phone_label: 'Teléfono',
    input_code_label: 'Código',
    creating_account_title: 'Creando una cuenta',
    terms_and_policy1: 'Al crear una cuenta',
    terms_and_policy2: ', acepto',
    terms_and_policy3: ' los ',
    terms_and_policy4: 'Términos de uso',
    terms_and_policy5: ' y ',
    terms_and_policy6: 'Política de privacidad',
    email_verification_code1: 'Por favor ingresa el código de verificación de 6 dígitos',
    email_verification_code2: 'que fue enviado a',
    email_verification_code3: 'El código es válido por 30 minutos.',
    email_verification_label: 'Código de verificación de correo electrónico',
    home_adress_title: 'Dirección de residencia',
    home_adress_description: 'Asegúrate de que esta sea tu dirección actual.',
    full_adress_label: 'Dirección completa',
    postal_code_label: 'Código postal',
    city_label: 'Ciudad',
    region_label: 'Región',
    password_creation_title: 'Contraseña',
    password_check_label: 'Verificar contraseña',
    personal_information_title: 'Información personal',
    personal_information_description1: 'Por favor proporciona la siguiente información tal como aparece',
    personal_information_description2: 'en tu pasaporte o documento de identidad.',
    full_name_label: 'Nombre completo',
    data_about_user: 'Los datos deben coincidir con los del pasaporte o documento de identidad.',
    date_birth_label: 'Fecha de nacimiento',
    phone_verification_code_label: 'Código de verificación por teléfono',
    residensy_title: 'Vamos a verificarte',
    residensy_title_description: 'Primero selecciona tu país de residencia.',
    residency_text: 'Asegúrate de que tu país de residencia sea correcto.',
    residency_option: "Residencia",
    citizenship_option: "Ciudadanía",
    back_btn_text: "Atrás",
    document_select_title: 'Verificación de Documento',
    document_select_description: 'Selecciona el país/región emisor de tu documento.',
    document_select_warning: 'Usa un documento válido emitido por el gobierno',
    document_select_text1: 'Solo los siguientes documentos listados a continuación ',
    document_select_text2: 'serán aceptados; todos los demás documentos serán ',
    document_select_text3: 'rechazados.',
    id_card_text: 'Tarjeta de Identidad',
    passport_text: 'Pasaporte',
    kyc_info_title: 'Verificación de Documento',
    kyc_info_description1: 'Toma una foto de ambos lados de tu documento emitido por el gobierno ',
    kyc_info_description2: 'Tarjeta de Identidad.',
    kyc_info_description3: 'pasaporte',
    reqiurements_kyc1: 'El documento no ha expirado',
    reqiurements_kyc2: 'Es un documento original, no una copia o escaneo',
    reqiurements_kyc3: 'Quita cualquier funda o cubierta para evitar reflejos o desenfoques',
    reqiurements_kyc4: 'Coloca el documento sobre un fondo de color sólido',
    reqiurements_kyc5: 'El documento debe sostenerse derecho, sin inclinarse ni girarse.',
    picture_card_text: 'Arrastra tu foto aquí o selecciona',
    picture_card_signed_text: 'Haz clic para explorar',
    picture_box_front: 'Frente de la tarjeta de identidad',
    picture_box_back: 'Reverso de la tarjeta de identidad',
    picture_passport: 'Foto del pasaporte',
    kyc_person_label: 'Verificación de Personalidad',
    kyc_person_description: 'Toma una foto tuya y del documento en tus manos.',
    photo_label: 'Foto tuya',
    person_requirements1: 'La imagen debe ser nítida, sin ningún desenfoque o pixelación.',
    person_requirements2: 'Tanto la cara del usuario como el documento deben ser completamente visibles y no recortados.',
    person_requirements3: 'La información en el documento debe ser claramente visible y no estar cubierta por los dedos.',
    person_requirements4: 'El documento debe sostenerse derecho, sin inclinarse ni girarse.',
    verify_btn: 'Verificar',
    verify_main_text: 'Ahora verifiquemos tu identidad para usar todas las funciones de Quanta.',
    acccount_created_title: 'cuenta creada',
    account_verified_title: 'cuenta verificada',
    registartion_success_title: 'Registro exitoso',
    auth_success_title: 'Autorización exitosa',
    auth_login: 'Ahora utiliza todas las funciones de Quanta',
    kyc_text: 'Se te notificará sobre los resultados más tarde.',
    kyc_title: 'KYC enviado para verificación',
    not_now: 'No ahora',
    password_requirements1: "Al menos 8 caracteres",
    password_requirements2: "Al menos 1 número",
    password_requirements3: "Al menos 1 letra mayúscula",
    password_requirements4: "Las contraseñas deben coincidir",
    apple_btn: "Registrarse con Apple",
    adress_palceholder: "Dirección",
    first_name_placeholder: "Nombre",
    last_name_placeholder: "Apellido",
    invalid_file_type: 'Tipo de archivo no válido. Por favor, cargue una imagen en formato PNG, JPEG o JPG.',
    incorrect_code: 'El código ingresado es incorrecto. Por favor, inténtelo de nuevo.',
    upload_file: 'Por favor, cargue un archivo.',
    missing_token: 'Falta el token de autorización.',
    picture_missing: 'Falta la imagen del documento (delante o detrás).',
    document_type: 'Falta el tipo de documento o el país.',
    existing_document: 'Ya existe un documento para este usuario.',
    failed_kyc: 'Error al enviar los detalles de KYC.',
    unexpected_error: 'Ocurrió un error inesperado. Por favor, intente nuevamente.',
    password_changed: 'Contraseña cambiada. Regrese a la página anterior.',
    failsed_reset_password: 'Error al restablecer la contraseña.',
    error_occured: 'Se produjo un error durante el restablecimiento.',
    login_failed: 'Error al iniciar sesión. Por favor, intente de nuevo.',
    email_send_error: 'Error al enviar el código de verificación por correo electrónico.',
    parsing_error: 'Ocurrió un error inesperado mientras se analizaba la respuesta.',
};
