import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { ArrowBackContainer, ArrowBackText, VerifyText } from "../../assets/styles/logIn.style";
import LeftArrow from "../../assets/img/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
export var BackArrow = function (_a) {
    var navigationLink = _a.navigationLink, position = _a.position, alignSelf = _a.alignSelf, top = _a.top, left = _a.left, right = _a.right, bottom = _a.bottom;
    var navigate = useNavigate();
    var _b = useState(left), calculatedLeft = _b[0], setCalculatedLeft = _b[1];
    useEffect(function () {
        var handleResize = function () {
            if (window.innerWidth <= 768) {
                setCalculatedLeft("5%");
            }
            else {
                setCalculatedLeft(left);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [left]);
    var handleClick = function () {
        if (navigationLink) {
            navigate(navigationLink);
        }
        else {
            navigate(-1);
        }
    };
    return (_jsxs(ArrowBackContainer, { onClick: handleClick, style: {
            position: position || "initial",
            alignSelf: alignSelf,
            top: top,
            left: calculatedLeft,
            right: right,
            bottom: bottom,
        }, children: [_jsx("img", { src: LeftArrow, alt: "Back" }), _jsx(ArrowBackText, { children: _jsx(VerifyText, { style: { cursor: "pointer" }, children: _jsx(FormattedMessage, { id: "back_btn_text", defaultMessage: "Back" }) }) })] }));
};
