import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from "react";
import AppleSignin from "react-apple-signin-auth";
import { SotialsBtnStyle, BtnIcon } from "../../assets/styles/logIn.style";
import AppleIcon from "../../assets/img/Apple-icon.svg";
import { FormattedMessage } from "react-intl";
import { PositionLayout } from "../../assets/styles/mainPageStyles";
var clientId = "ua.pp.quantaexchange.si";
var redirectURI = "https://quantaexchange.pp.ua/auth";
export var AppleButton = function () {
    var appleButtonRef = useRef(null);
    var onSuccess = function (response) {
        console.log("Auth is success", response);
        if (response && response.authorization) {
            console.log("Authorization Code:", response.authorization.code);
            console.log("ID Token:", response.authorization.id_token);
            console.log("Access Token:", response.authorization.access_token);
        }
        else {
            console.error("No response");
        }
    };
    var onError = function (error) {
        console.error("Error:", error);
    };
    var handleClick = function () {
        var _a;
        if (appleButtonRef.current) {
            (_a = appleButtonRef.current.querySelector("button")) === null || _a === void 0 ? void 0 : _a.click();
        }
    };
    return (_jsxs(SotialsBtnStyle, { onClick: handleClick, children: [_jsx(BtnIcon, { src: AppleIcon, alt: "Apple account" }), _jsx(FormattedMessage, { id: "sign_up_with_apple" }), _jsx(PositionLayout, { ref: appleButtonRef, display: "none", children: _jsx(AppleSignin, { authOptions: {
                        clientId: clientId,
                        scope: "name email",
                        redirectURI: redirectURI,
                        state: "origin:web",
                    }, onSuccess: onSuccess, onError: onError }) })] }));
};
