import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { AlertContainer, AlertTextContainer, AuthInputLabel, ProgressBarContainer, ProgressFill, } from "../../assets/styles/logIn.style";
import AlertIcon from "../../assets/img/alert-svgrepo-com.svg";
import SuccessIcon from "../../assets/img/success-filled-svgrepo-com.svg";
import { FormattedMessage } from "react-intl";
import { PositionLayout } from "../../assets/styles/mainPageStyles";
export var Alert = function (_a) {
    var message = _a.message, onClose = _a.onClose, isSuccess = _a.isSuccess;
    useEffect(function () {
        var timer = setTimeout(function () {
            onClose();
        }, 10700);
        return function () { return clearTimeout(timer); };
    }, [onClose]);
    return (_jsxs(AlertContainer, { isSuccess: isSuccess, children: [_jsxs(AlertTextContainer, { children: [_jsx("img", { width: 25, height: 25, src: isSuccess ? SuccessIcon : AlertIcon, alt: "alert-icon" }), _jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: message, defaultMessage: message }) }), _jsx(PositionLayout, { position: "absolute", top: "10px", right: "10px", zIndex: 10, onClick: onClose, children: _jsx("img", { src: "".concat(process.env.REACT_APP_API_URL, "/images/header-burger-menu-close.webp"), height: 25, width: 25 }) })] }), _jsx(ProgressBarContainer, { children: _jsx(ProgressFill, { duration: 10 }) })] }));
};
