import { jsx as _jsx } from "react/jsx-runtime";
import React, { startTransition, Suspense, useEffect, useMemo, useState } from 'react';
import CanvasScene from "../components/globe/CanvasScene";
import { useLocation } from "react-router-dom";
import { useLineaEquation } from "../hooks/planetBlockHooks";
var AuthGlobeLayout = function () {
    var location = useLocation();
    var authStepHandler = function () { return (location.pathname === '/auth/login' ? 1 : 2); };
    var positionNumbers = [1, 2];
    var yPoints = [-2.3, -1];
    var _a = useState(authStepHandler()), step = _a[0], setStep = _a[1];
    var planetYPos = useLineaEquation({
        xPoints: positionNumbers,
        yPoints: yPoints,
    });
    var memoizedYPos = useMemo(function () { return planetYPos(step); }, [step]);
    useEffect(function () {
        startTransition(function () {
            setStep(authStepHandler());
        });
    }, [location.pathname]);
    return (_jsx("div", { style: {
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }, children: _jsx(Suspense, { fallback: _jsx("div", { children: "Loading Canvas..." }), children: _jsx(CanvasScene, { x: 1, y: memoizedYPos, z: -1.5, color: 0x3B1049 }) }) }));
};
export default AuthGlobeLayout;
