var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Link } from "react-router-dom";
export var HeaderContainer = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   margin: 0 auto;\n    max-width: 1440px;\n    height: 62px;\n    display: flex;\n    align-items: center;\n    font-family: \"Kodchasan\", sans-serif;\n    padding: 0 39px;\n    justify-content: space-between;\n    z-index: 2;\n    position: relative;\n\n    @media (max-width: 400px){\n        padding: 0 19px;\n    }\n"], ["\n   margin: 0 auto;\n    max-width: 1440px;\n    height: 62px;\n    display: flex;\n    align-items: center;\n    font-family: \"Kodchasan\", sans-serif;\n    padding: 0 39px;\n    justify-content: space-between;\n    z-index: 2;\n    position: relative;\n\n    @media (max-width: 400px){\n        padding: 0 19px;\n    }\n"])));
export var HeaderFullScreenContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n\n    @media (max-width: 950px){\n        display: none;\n    }\n\n"], ["\n    display: flex;\n\n    @media (max-width: 950px){\n        display: none;\n    }\n\n"])));
export var HeaderBurgerMenu = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: none;\n\n    @media (max-width: 950px) {\n        display: flex;\n        height: 50px;\n        width: 50px;\n        background-repeat: no-repeat;\n        background-size: 100%;\n        cursor: pointer;\n        z-index: 10000;\n        background-image: url(", ");\n    }\n\n\n    @media (max-width: 700px) {\n        .launchButton {\n            display: none !important;\n        }\n    }\n"], ["\n    display: none;\n\n    @media (max-width: 950px) {\n        display: flex;\n        height: 50px;\n        width: 50px;\n        background-repeat: no-repeat;\n        background-size: 100%;\n        cursor: pointer;\n        z-index: 10000;\n        background-image: url(", ");\n    }\n\n\n    @media (max-width: 700px) {\n        .launchButton {\n            display: none !important;\n        }\n    }\n"])), function (props) { return props.$isOpen
    ? "".concat(process.env.REACT_APP_API_URL, "/images/header-burger-menu-close.webp")
    : "".concat(process.env.REACT_APP_API_URL, "/images/header-burger-menu.webp"); });
export var HeaderBurgerContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n"], ["\n    display: flex;\n    align-items: center;\n"])));
export var HeaderButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    position: relative;\n    display: flex;\n    gap: 13px;\n\n    @media(max-width: 950px){\n        margin-top: 20px;\n    }\n\n"], ["\n    position: relative;\n    display: flex;\n    gap: 13px;\n\n    @media(max-width: 950px){\n        margin-top: 20px;\n    }\n\n"])));
export var HeaderLanguageButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 8px 14px 8px 20px;\n    color: #fff;\n    font-size: 14px;\n    font-weight: 700;\n    line-height: 18px;\n    background-color: #13131E;\n    border-radius: 16px;\n\n    @media(max-width: 950px){\n        background-color: #000004;\n    }\n\n    @media(max-width: 700px){\n        padding: 18px 24px 18px 30px;\n    }\n"], ["\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    padding: 8px 14px 8px 20px;\n    color: #fff;\n    font-size: 14px;\n    font-weight: 700;\n    line-height: 18px;\n    background-color: #13131E;\n    border-radius: 16px;\n\n    @media(max-width: 950px){\n        background-color: #000004;\n    }\n\n    @media(max-width: 700px){\n        padding: 18px 24px 18px 30px;\n    }\n"])));
export var HeaderLanguageIcon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\n    height: 22px;\n    width: 22px;\n    background-image: url(\"", "/images/header-language-icon.webp\");\n    background-size: cover;\n    background-position: center;\n\n"], ["\n\n    height: 22px;\n    width: 22px;\n    background-image: url(\"", "/images/header-language-icon.webp\");\n    background-size: cover;\n    background-position: center;\n\n"])), process.env.REACT_APP_API_URL);
export var HeaderLaunchButton = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    text-decoration: none;\n    cursor: pointer;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    background-color: #FFB801;\n    color: #13131E;\n    line-height: 18px;\n    font-size: 14px;\n    font-weight: 700;\n    border-radius: 16px;\n    padding: 8px 14px 8px 20px;\n    box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n    transition: all 0.1s linear 0s;\n\n    &:hover {\n        background-color: #FFE247;\n        box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n        box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n        color: #000004;\n        background-color: #E29500;\n    }\n\n    @media(max-width: 700px){\n        padding: 18px 24px 18px 30px;\n    }\n"], ["\n    text-decoration: none;\n    cursor: pointer;\n    display: flex;\n    gap: 12px;\n    align-items: center;\n    background-color: #FFB801;\n    color: #13131E;\n    line-height: 18px;\n    font-size: 14px;\n    font-weight: 700;\n    border-radius: 16px;\n    padding: 8px 14px 8px 20px;\n    box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n    transition: all 0.1s linear 0s;\n\n    &:hover {\n        background-color: #FFE247;\n        box-shadow: 0 8px 14px -4px rgba(255, 231, 12, 0.49);\n    }\n\n    &:active {\n        box-shadow: 0 0 0 0 rgba(255, 231, 12, 0.49);\n        color: #000004;\n        background-color: #E29500;\n    }\n\n    @media(max-width: 700px){\n        padding: 18px 24px 18px 30px;\n    }\n"])));
export var HeaderLaunchIcon = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\n    height: 22px;\n    width: 22px;\n    background-image: url(\"", "/images/header-launch-icon.webp\");  \n    background-size: cover;\n    background-position: center;\n\n"], ["\n\n    height: 22px;\n    width: 22px;\n    background-image: url(\"", "/images/header-launch-icon.webp\");  \n    background-size: cover;\n    background-position: center;\n\n"])), process.env.REACT_APP_API_URL);
export var HeaderDropdown = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    position: absolute;\n    top: 120%;\n    background-color: #13131E;\n    color: #FFFFFF;\n    border-radius: 16px;\n    width: 150px;\n    z-index: 1000;\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);\n    overflow: hidden;\n\n    @media(max-width: 950px){\n        display: none;\n    }\n"], ["\n    position: absolute;\n    top: 120%;\n    background-color: #13131E;\n    color: #FFFFFF;\n    border-radius: 16px;\n    width: 150px;\n    z-index: 1000;\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);\n    overflow: hidden;\n\n    @media(max-width: 950px){\n        display: none;\n    }\n"])));
export var HeaderBurgerList = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    display: none;\n\n\n    @media(max-width: 950px){\n        display: flex;\n        flex-direction: column;\n    }\n"], ["\n    display: none;\n\n\n    @media(max-width: 950px){\n        display: flex;\n        flex-direction: column;\n    }\n"])));
export var HeaderDropdownItem = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    padding: 15px 15px 15px 10px;\n    cursor: pointer;\n    &:hover {\n        background-color: #000004;\n    }\n\n    @media(max-width: 950px){\n        &:hover {\n            background-color: transparent;\n        }\n    }\n"], ["\n    padding: 15px 15px 15px 10px;\n    cursor: pointer;\n    &:hover {\n        background-color: #000004;\n    }\n\n    @media(max-width: 950px){\n        &:hover {\n            background-color: transparent;\n        }\n    }\n"])));
export var DropdowmLink = styled(Link)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n\n    color: #D9D9E3;\n    font-size: 12px;\n    text-decoration: none;\n    &:hover {\n        color: #FFFFFF;\n    }\n\n    &:hover {\n        filter: brightness(100%);\n    }\n\n    @media(max-width: 900px){\n        font-size: 16px;\n    }\n"], ["\n\n    color: #D9D9E3;\n    font-size: 12px;\n    text-decoration: none;\n    &:hover {\n        color: #FFFFFF;\n    }\n\n    &:hover {\n        filter: brightness(100%);\n    }\n\n    @media(max-width: 900px){\n        font-size: 16px;\n    }\n"])));
export var HeaderBurgerMenuContent = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: none;\n\n  @media (max-width: 950px) {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    top: 0.7%;\n    right: 0%;\n    background-color: #14141C;\n    width: 100%;\n    height: 100vh;\n    z-index: 100;\n    padding: 21px;\n    border-radius: 21px;\n    align-items: center;\n  }\n\n\n"], ["\n  display: none;\n\n  @media (max-width: 950px) {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    top: 0.7%;\n    right: 0%;\n    background-color: #14141C;\n    width: 100%;\n    height: 100vh;\n    z-index: 100;\n    padding: 21px;\n    border-radius: 21px;\n    align-items: center;\n  }\n\n\n"])));
export var VisibilityBurgerContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    @media(max-width: 950px){\n        display: none;\n    }\n"], ["\n    @media(max-width: 950px){\n        display: none;\n    }\n"])));
export var VisibilityInBurgerLaunchBtn = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin-top: 20px;\n    @media(min-width: 700px){\n        display: none;\n    }\n"], ["\n    margin-top: 20px;\n    @media(min-width: 700px){\n        display: none;\n    }\n"])));
export var VisibilityBurgerLaunchBtn = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    padding-left: 10px;\n    @media(max-width: 700px){\n        display: none;\n    }\n"], ["\n    padding-left: 10px;\n    @media(max-width: 700px){\n        display: none;\n    }\n"])));
export var HeaderBurgerMenuBottomContent = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    // display: flex;\n    gap: 5px;\n"], ["\n    // display: flex;\n    gap: 5px;\n"])));
export var LanguageModalOverlay = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n"])));
export var LanguageModalContent = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  background-color: #13131E;\n  padding: 20px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 300px;\n"], ["\n  background-color: #13131E;\n  padding: 20px;\n  border-radius: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 300px;\n"])));
export var ModalCloseButton = styled.button(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n  color: #fff;\n  font-size: 18px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n"], ["\n  background-color: transparent;\n  border: none;\n  color: #fff;\n  font-size: 18px;\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
