import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputWrapper, IconWrapper, AuthInput, AuthInputLabel, FormContainer, LogInTitle, AuthLink } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, PositionLayout, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import React, { useState } from "react";
import { BackArrow } from "../../../components/authComponents/BackButton";
import UFOIcon from "../../../components/UFOIcon/UFOIcon";
import { useNavigate } from "react-router-dom";
import { hasCapitalLetter, hasNumber, isEmail, isLongEnough, isPhone } from "../../../const/validations/validation";
import { useUserLoginStore } from "../../../store/user/setUser";
import { FormattedMessage, useIntl } from "react-intl";
export var LogIn = function () {
    var _a = useState(true), isHidePass = _a[0], setIsHidePass = _a[1];
    var navigate = useNavigate();
    var _b = useState(''), emailOrPhone = _b[0], setEmailOrPhone = _b[1];
    var _c = useState(''), password = _c[0], setPassword = _c[1];
    var _d = useUserLoginStore(), userObj = _d.userObj, addProp = _d.addProp;
    var intl = useIntl();
    var validateForm = function () {
        var isEmailValid = isEmail(emailOrPhone);
        var isPhoneValid = isPhone(emailOrPhone);
        return (isEmailValid || isPhoneValid) && isLongEnough(password) && hasNumber(password) && hasCapitalLetter(password);
    };
    var handleEmailOrPhoneChange = function (e) {
        setEmailOrPhone(e.target.value);
    };
    var handlePasswordChange = function (e) {
        setPassword(e.target.value);
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        if (isEmail(emailOrPhone)) {
            addProp("email", emailOrPhone);
            addProp("password", password);
            navigate("/auth/email-verify", { state: { email: emailOrPhone } });
        }
        else if (isPhone(emailOrPhone)) {
            addProp("phone", emailOrPhone);
            addProp("password", password);
            navigate("/auth/phone-verify", { state: { email: emailOrPhone } });
        }
    };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsxs(StyledCol, { "$justifyContent": "center", "$lg": 7, "$md": 12, "$sm": 9, "$xs": 12, children: [_jsx(BackArrow, { position: "absolute", top: '10%', left: "10%" }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 12, children: _jsxs(FormContainer, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "log_in_title", defaultMessage: "welcome back" }) }), _jsxs(PositionLayout, { as: 'form', onSubmit: handleSubmit, display: "flex", flexDirection: "column", children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_phone_input_label", defaultMessage: "Email/Phone number" }) }), _jsx(AuthInput, { value: emailOrPhone, onChange: handleEmailOrPhoneChange, placeholder: intl.formatMessage({ id: 'email_phone_input_label', defaultMessage: "Email/Phone number" }), type: "email", "data-testid": "email-input" }), _jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "password_input_label", defaultMessage: "Password" }) }), _jsxs(InputWrapper, { children: [_jsx(AuthInput, { value: password, onChange: handlePasswordChange, placeholder: intl.formatMessage({ id: 'password_input_label', defaultMessage: 'Password' }), type: isHidePass ? 'password' : 'text', "data-testid": "password-input" }), _jsx(IconWrapper, { onClick: function () { return setIsHidePass(!isHidePass); }, children: _jsx(UFOIcon, { isHidePass: isHidePass }) })] }), _jsx(MainBtnStyle, { type: "submit", width: "50%", disabled: !validateForm(), style: {
                                            opacity: validateForm() ? "100%" : "50%",
                                            cursor: validateForm() ? "pointer" : "not-allowed",
                                        }, children: _jsx(FormattedMessage, { id: "next_btn", defaultMessage: "Next" }) }), _jsx(AuthLink, { onClick: function () { return navigate('/auth/reset-password'); }, children: _jsx(FormattedMessage, { id: "didt_remember_password", defaultMessage: "Don't remember a password?" }) })] })] }) })] }) }));
};
