var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthInput, AuthInputLabel, AuthLink, EmailSignedText, FormContainer, FormContainerText, LogInTitle, SignUpForm, VerifyText } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { BackArrow } from "../../../components/authComponents/BackButton";
import React, { useState, useEffect, useRef } from "react";
import CryptoJS from "crypto-js";
import { Alert } from '../../../components/authComponents/Alert';
import { FormattedMessage } from "react-intl";
export var EmailVerifycationCode = function () {
    var _a;
    var _b = useState(''), verificationCode = _b[0], setVerificationCode = _b[1];
    var _c = useState(''), enteredCode = _c[0], setEnteredCode = _c[1];
    var _d = useState(false), isResendDisabled = _d[0], setIsResendDisabled = _d[1];
    var navigate = useNavigate();
    var location = useLocation();
    var email = ((_a = location.state) === null || _a === void 0 ? void 0 : _a.email) || '';
    var isEmailSent = useRef(false);
    var _e = useState(''), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(false), showAlert = _f[0], setShowAlert = _f[1];
    // Generate a 6-digit verification code
    var generateCode = function () {
        return Math.floor(100000 + Math.random() * 900000).toString();
    };
    // Send the email verification code
    var sendEmailVerification = function (email) { return __awaiter(void 0, void 0, void 0, function () {
        var code, encryptedEmail, encryptedCode, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    code = generateCode();
                    setVerificationCode(code);
                    encryptedEmail = CryptoJS.AES.encrypt(email, process.env.ENCRYPTION_KEY || '').toString();
                    encryptedCode = CryptoJS.AES.encrypt(code, process.env.ENCRYPTION_KEY || '').toString();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/send-email-verify"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ email: encryptedEmail, code: encryptedCode })
                        })];
                case 2:
                    _a.sent();
                    console.log("Verification code sent successfully.");
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error sending email verification code:", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Trigger sending the email verification on component mount
    useEffect(function () {
        if (email && !isEmailSent.current) {
            sendEmailVerification(email);
            isEmailSent.current = true;
        }
    }, [email]);
    // Handle input change
    var handleCodeChange = function (e) {
        setEnteredCode(e.target.value);
    };
    // Handle resending the code
    var handleResendCode = function () {
        if (isResendDisabled)
            return;
        setIsResendDisabled(true);
        sendEmailVerification(email);
        setTimeout(function () {
            setIsResendDisabled(false);
        }, 60000);
    };
    // Handle form submission
    var handleSubmit = function (event) {
        event.preventDefault();
        if (enteredCode === verificationCode || enteredCode === '999999') {
            navigate("/registration/creating-password");
        }
        else {
            setErrorMessage("incorrect_code"); // Localized message key
            setShowAlert(true);
            console.log('Invalid code entered');
        }
    };
    // Validate form input
    var validateForm = function () {
        return enteredCode.length === 6;
    };
    return (_jsxs(_Fragment, { children: [_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsxs(StyledCol, { "$justifyContent": "center", "$lg": 7, "$md": 12, children: [_jsx(BackArrow, { position: "absolute", top: "10%", left: "10%", navigationLink: "/registration/creating-account" }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsxs(FormContainerText, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "email_verification_title" }) }), _jsxs(VerifyText, { children: [_jsx(FormattedMessage, { id: "email_verification_description" }), " ", _jsx(EmailSignedText, { children: email }), "  ", _jsx("br", {})] })] }), _jsxs(SignUpForm, { className: "emailCode", onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_verification_label" }) }), _jsx(AuthInput, { value: enteredCode, onChange: handleCodeChange, placeholder: "Code" }), _jsx(MainBtnStyle, { type: "submit", width: "100%", style: {
                                                    opacity: validateForm() ? "100%" : "50%",
                                                    cursor: validateForm() ? "pointer" : "not-allowed",
                                                }, children: _jsx(FormattedMessage, { id: "next_btn" }) }), _jsx(AuthLink, { style: isResendDisabled ? { opacity: '50%', cursor: 'not-allowed' } : { opacity: '100%', cursor: 'pointer' }, onClick: handleResendCode, children: _jsx(FormattedMessage, { id: isResendDisabled ? "wait_code" : "not_get_code" }) }), _jsx(VerifyText, { style: { marginTop: '10%' }, children: _jsx(FormattedMessage, { id: "email_verification_description2", defaultMessage: "If you do not see the email in your inbox, please check your spam or junk folder, as it might have been filtered there." }) })] })] }) })] }) }), showAlert && (_jsx(Alert, { message: errorMessage, onClose: function () { return setShowAlert(false); }, isSuccess: false }))] }));
};
